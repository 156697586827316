import React, { memo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Checkbox, FormControlLabel, styled, Switch } from '@mui/material';
import { t } from 'i18next';

import FormSelectField from 'components/ui/form/FormSelectField';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

export default memo(function DiscountPanel({
  setApplyDiscount,
  applyDiscount,
  formik,
  object_id,
}) {
  return (
    <div className='!w-full'>
      <FormControlLabel
        control={<IOSSwitch />}
        labelPlacement='start'
        sx={{
          justifyContent: 'space-between',
          marginLeft: '0',
          width: '100%',
          marginBottom: '8px',
        }}
        value={applyDiscount}
        disabled={!formik.values.discountId}
        label={t('shaxmatka.moreHomeDetails.applyDiscount')}
        onChange={({ target }) => setApplyDiscount(target?.checked)}
      />
      <FormSelectField
        formik={formik}
        label={t('settings.discounts.title')}
        fieldName='discountId'
        path={`admin/discount/objects/${object_id}`}
        className='!bg-white'
      />
    </div>
  );
});
