import { memo } from 'react';
import Entrance from './Entrance';
import { motion } from 'framer-motion';

export default memo(function Block({ block, blockIndex }) {
  return (
    <motion.div
      animate={{ x: 0, opacity: 1 }}
      initial={{ x: 50, opacity: 0 }}
      transition={{ delay: blockIndex * block?.padezd?.length * 0.1 + 0.1 }}
      className='flex h-fit gap-3 min-w-fit sheet-column'
    >
      {block?.padezd?.map((entrance, i) => (
        <Entrance
          key={entrance?.id}
          entrance={entrance}
          entranceIndex={i}
          blockName={block?.name}
        />
      ))}
    </motion.div>
  );
});
