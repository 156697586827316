const MENU_PERMISSIONS_LIST = [
  {
    code: 'MAINPAGE',
    linkData: {
      path: 'admin/dashboard',
      title: 'sidebar.admin.dashboard',
    },
    iconName: 'bi bi-house',
    order: 1,
  },
  {
    code: 'SHOWROOM',
    linkData: {
      path: 'admin/shaxmatka',
      title: 'sidebar.admin.shaxmatka',
    },
    iconName: 'bi bi-grid-3x3-gap',
    order: 2,
  },
  {
    code: 'SHOWROOM-2',
    linkData: {
      path: 'admin/showroom',
      title: 'sidebar.admin.showroom',
    },
    iconName: 'bi bi-grid-3x3-gap',
    order: 3,
  },
  {
    code: 'HOMES',
    linkData: {
      path: 'admin/homes',
      title: 'sidebar.admin.homes',
    },
    iconName: 'bi bi-houses',
    order: 4,
  },
  {
    code: 'ORDER',
    linkData: {
      path: 'admin/order',
      title: 'sidebar.admin.order',
    },
    iconName: 'bi bi-stopwatch',
    order: 5,
  },
  {
    code: 'OBJECTS',
    linkData: {
      path: 'admin/object',
      title: 'sidebar.admin.object',
    },
    iconName: 'bi bi-houses',
    order: 6,
  },
  {
    code: 'CONTRACTS',
    linkData: {
      path: 'admin/contract',
      title: 'sidebar.admin.contract',
    },
    iconName: 'bi bi-clipboard-check',
    order: 7,
  },
  {
    code: 'PAYMENTS',
    linkData: {
      path: 'admin/payment',
      title: 'sidebar.admin.payment',
    },
    iconName: 'bi bi-cash-coin',
    order: 8,
  },
  {
    code: 'CUSTOMS',
    linkData: {
      path: 'admin/client',
      title: 'sidebar.admin.client',
    },
    iconName: 'bi bi-people',
    order: 9,
  },
  {
    code: 'NEWS',
    linkData: {
      path: 'admin/news',
      title: 'sidebar.admin.news',
    },
    iconName: 'bi bi-newspaper',
    order: 10,
  },
  {
    code: 'DEBITORS',
    linkData: {
      path: 'admin/arrears',
      title: 'sidebar.admin.arrears',
    },
    iconName: 'bi bi-cash-stack',
    order: 11,
  },
  {
    code: 'CHANGES',
    linkData: {
      path: 'admin/change',
      title: 'sidebar.admin.change',
    },
    iconName: 'bi bi-arrow-repeat',
    order: 12,
  },
  {
    code: 'PAYMENTS_CHANGES',
    linkData: {
      path: 'admin/payment-change',
      title: 'sidebar.admin.paymentChange',
    },
    iconName: 'bi bi-cash-stack',
    order: 13,
  },
  {
    code: 'TASKS',
    linkData: {
      path: 'admin/tasks',
      title: 'sidebar.admin.tasks',
    },
    iconName: 'bi bi-journal-bookmark',
    order: 14,
  },
  {
    code: 'CRM',
    linkData: {
      path: 'admin/crm',
      title: 'sidebar.admin.crm',
    },
    iconName: 'bi bi-grid-1x2',
    order: 15,
  },
  {
    code: 'STATISTICS',
    linkData: {
      path: 'boss/dashboard',
      title: 'sidebar.admin.boss',
    },
    iconName: 'bi bi-person-workspace',
    order: 16,
  },
  {
    code: 'OBJECTS',
    linkData: {
      path: 'admin/block',
      title: 'sidebar.admin.block',
    },
    iconName: 'bi bi-buildings',
    order: 17,
  },
  {
    code: 'SETTINGS',
    linkData: {
      path: 'admin/settings',
      title: 'sidebar.admin.settings',
    },
    iconName: 'bi bi-gear',
    order: 18,
  },
];

export default MENU_PERMISSIONS_LIST;
