import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { fadeUp } from 'utils/motion';

const SimpleRadioButtonGroupField = ({
  delay = 0,
  duration = 0.5,
  label,
  name,
  value,
  options = [],
  changeFn = () => {},
  disabled = false,
  className = '',
  size = 'medium',
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      className={className ? `!my-0 ${className}` : '!my-0'}
      fullWidth
      component={motion.div}
      variants={fadeUp(30, 'tween', delay, duration)}
      initial='hidden'
      animate='show'
      viewport={{ once: true, amount: 0.25 }}
      color='formColor'
      type='radiogroup'
    >
      <FormLabel id={`${name}-radio-buttons-group`} className='mb-3.5'>
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby={`${name}-radio-buttons-group`}
        name={`${name}-radio-buttons-group-name`}
        value={value}
        onChange={(event) => changeFn(event.target.value)}
      >
        {options &&
          options.length > 0 &&
          options.map((option) => (
            <label
              key={option?.code}
              className={`mr-2 font-light px-3 py-2 text-center base-border cursor-pointer transition-all rounded-md ${
                value == option?.code
                  ? 'bg-[#7947F81A] border-[#7947F8] text-[#7947F8]'
                  : 'bg-white'
              }`}
            >
              <Radio
                value={option?.code}
                className='!w-0 !opacity-0 !overflow-hidden !p-0'
              />
              {typeof option.label == 'object'
                ? t(...option.label)
                : t(option.label)}
            </label>
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default SimpleRadioButtonGroupField;
