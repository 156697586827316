import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { fadeUp } from 'utils/motion';

const SimpleToggleButtonGroup = ({
  delay = 0,
  duration = 0.5,
  options = [],
  value = '',
  size = 'medium',
  fullWidth = false,
  buttonClassName = '',
  changeFn = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <motion.div
      variants={fadeUp(30, 'tween', delay, duration)}
      initial='hidden'
      animate='show'
      viewport={{ once: true, amount: 0.25 }}
      className={fullWidth ? 'w-full' : ''}
    >
      <ToggleButtonGroup
        component={motion.div}
        variant='simple'
        color='primary'
        value={value}
        exclusive
        onChange={(event) => changeFn(event.target.value)}
        aria-label='simple-toggle-button-group'
        size={size}
        fullWidth={fullWidth}
        className='!bg-gray-100 !shadow-none !rounded-lg !border-none'
      >
        {options &&
          options.length > 0 &&
          options.map((item, index) => (
            <ToggleButton
              color='primary'
              value={item.code}
              key={`toggle-btn-${item.code}-${index}`}
              className={buttonClassName}
              sx={{
                '&.MuiButtonBase-root.MuiToggleButton-root': {
                  background: 'transparent',
                  color: 'black',
                  height: '25px',
                  borderRadius: '7px',
                  padding: '3.5px 10px',
                },
                '&.MuiButtonBase-root.MuiToggleButton-root.Mui-selected': {
                  background: 'white',
                  color: 'black',
                },
              }}
            >
              {t(item.label)}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </motion.div>
  );
};

export default SimpleToggleButtonGroup;
