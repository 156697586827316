import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { CircularProgress, IconButton } from '@mui/material';
import { Fragment, memo, useMemo, useRef, useState } from 'react';
import LightBox from './ui/dialogs/LightBox';

const ImagesSwiper = ({ imageURLs = [] }) => {
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
    useState(false);

  const images = useMemo(() => {
    const imgs = [];
    const titles = [];
    const captions = [];
    if (imageURLs?.[0]) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${imageURLs?.[0]}`;
      imgs.push(link);
      titles.push('3d image');
      captions.push('3d image');
    }
    if (imageURLs?.[1]) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${imageURLs?.[1]}`;
      imgs.push(link);
      titles.push('2d image');
      captions.push('2d image');
    }
    if (imageURLs?.[2]) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${imageURLs?.[2]}`;
      imgs.push(link);
      titles.push('location');
      captions.push('location');
    }
    return { imgs, titles, captions };
  }, [imageURLs]);

  return (
    <div className='images-swiper-wrapper'>
      <Swiper
        lazy='true'
        navigation={false}
        modules={[Navigation, Pagination]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(a) => {
          setSliderActiveIndex(a?.activeIndex);
        }}
      >
        {images?.imgs.length > 0 &&
          images?.imgs.map((item, index) => (
            <SwiperSlide key={`swiper-item-${index}`} className='!shadow-none'>
              <div
                className='swiper-zoom-container cursor-pointer !shadow-none'
                onClick={() => setOpenHomeLayoutImageDialog(true)}
              >
                <img src={item} alt='' loading='lazy' />
              </div>
              <div className='swiper-lazy-preloader !animate-none !border-none'>
                <CircularProgress size={40} sx={{ color: 'white' }} />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      {imageURLs && imageURLs.length > 1 && (
        <Fragment>
          <div
            className={`swiper-navigation-button-wrapper prev${
              sliderActiveIndex == 0 ? ' is-disabled' : ''
            }`}
          >
            <IconButton
              variant='swiperNavigationButton'
              color='primary'
              onClick={() => {
                swiperRef.current?.slidePrev();
              }}
              disabled={sliderActiveIndex == 0}
              size='small'
            >
              <i className='bi bi-chevron-left' />
            </IconButton>
          </div>
          <div
            className={`swiper-navigation-button-wrapper next${
              sliderActiveIndex == imageURLs.length - 1 ? ' is-disabled' : ''
            }`}
          >
            <IconButton
              variant='swiperNavigationButton'
              color='primary'
              onClick={() => {
                swiperRef.current?.slideNext();
              }}
              disabled={sliderActiveIndex == imageURLs.length - 1}
              size='small'
            >
              <i className='bi bi-chevron-right' />
            </IconButton>
          </div>
          <div className='swiper-pagination-wrapper'>
            <span className='swiper-pagination-details !shadow-none gap-1'>
              {imageURLs?.map((img, i) => (
                <span
                  key={img}
                  className={`swiper-pagination-button transition-all mx-0.5 cursor-pointer inline-block rounded-full h-1.5 ${
                    i === sliderActiveIndex
                      ? 'active w-4 bg-[#7947F8]'
                      : 'w-1.5 bg-[#E3E3E3]'
                  }`}
                />
              ))}
              {/* {sliderActiveIndex + 1}/{imageURLs.length} */}
            </span>
          </div>
        </Fragment>
      )}
      {openHomeLayoutImageDialog && images?.imgs?.length > 0 ? (
        // yet-another-react-lightbox
        <LightBox
          isOpen={openHomeLayoutImageDialog}
          style={{ zIndex: 999 }}
          captions={images?.captions}
          titles={images?.titles}
          images={images?.imgs}
          photoIndex={sliderActiveIndex}
          setIsOpen={setOpenHomeLayoutImageDialog}
        />
      ) : null}
    </div>
  );
};
export default memo(ImagesSwiper);
