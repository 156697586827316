import { createContext } from 'react';
import { useAppInfo } from './AppInfoContext';
import { useShowroom } from './ShowroomContext';

const AuthContext = createContext({});

const NotificationContext = createContext({});

const CRMLeadsContext = createContext({});

const CurrencyContext = createContext({});

const MenuContext = createContext({});

const PermissionContext = createContext({});

const TopPanelContext = createContext({});

export {
  AuthContext,
  NotificationContext,
  CRMLeadsContext,
  CurrencyContext,
  TopPanelContext,
  MenuContext,
  PermissionContext,
  useAppInfo,
  useShowroom,
};
