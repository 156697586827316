/* eslint-disable eqeqeq */
import { Box, Tab, Tabs } from '@mui/material';
import { t } from 'i18next';
import { memo, useState } from 'react';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useSearch from 'hooks/useSearch';
import { useLocation } from 'react-router-dom';
import Shaxmatka from './shaxmatka';
import ShowroomHomeDetail from './home-modals/ShowroomHomeDetail';

export default memo(function ShowroomPanel() {
  const { search } = useLocation();
  const query = useSearch();
  const axiosPrivate = useAxiosPrivate();
  const [tabIndex, setTabIndex] = useState(
    localStorage.getItem('showroom-tab') || '1'
  );
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
    localStorage.setItem('showroom-tab', newValue);
  };
  const homesApi = useQuery({
    queryKey: ['/admin/home/padezd/', search],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/home/padezd/${query?.get('objectId')}${search}`
      );
      return response.data.data;
    },
    enabled: !!query?.get('objectId'),
    retry: false,
  });
  return (
    <div className='flex h-full w-full min-h-[400px] gap-4'>
      <div
        className={
          'base-border grow bg-white h-full p-5 w-[calc(100%-320px)] basis-[calc(100%-320px)]'
        }
      >
        <Box
          sx={{
            background: '#76768014',
            padding: '3px 5px',
            borderRadius: '15px',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            variant='fullWidth'
            type='callsHistory'
            className='custom-tab-fulfilled'
          >
            <Tab
              label={t('showroom.type.table')}
              value={'1'}
              sx={{ textTransform: 'none' }}
            />
            <Tab
              label={t('showroom.type.showroom')}
              value={'2'}
              sx={{ textTransform: 'none' }}
            />
            <Tab
              label={t('showroom.type.showroomPlus')}
              value={'3'}
              sx={{ textTransform: 'none' }}
            />
            <Tab
              label={t('showroom.type.visualView')}
              value={'4'}
              sx={{ textTransform: 'none' }}
            />
          </Tabs>
        </Box>
        <div className='h-[calc(100%-50px)] w-full'>
          {tabIndex == 2 && (
            <Shaxmatka
              homes={homesApi?.data}
              isLoading={homesApi?.isLoading || homesApi?.isFetching}
            />
          )}
        </div>
      </div>
      <ShowroomHomeDetail />
    </div>
  );
});
