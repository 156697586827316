import React, { memo } from 'react';
import SearchInput from 'components/SearchInput';
import { InputAdornment } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export default memo(function ShowroomTopFilter() {
  return (
    <div className='flex items-center gap-2'>
      <SearchInput
        inputKey='rooms'
        label={t('common.filter.roomsNumber')}
        type='number'
        InputProps={{ endAdornment: null }}
      />
      <div className='flex w-[300px]'>
        <SearchInput
          inputKey='valute_from'
          label=''
          placeholder={t('common.table.from')}
          type='number'
          InputProps={{
            sx: {
              borderRadius: '8px 0 0 8px !important',
            },
            endAdornment: null,
            startAdornment: (
              <InputAdornment position='start' className='custom-endAdornment'>
                USD
              </InputAdornment>
            ),
          }}
        />
        <SearchInput
          inputKey='valute_till'
          label=''
          placeholder={t('common.table.till')}
          type='number'
          InputProps={{
            sx: {
              borderRadius: '0 8px 8px 0 !important',
            },
            endAdornment: null,
          }}
        />
      </div>
      <div className='flex w-[300px]'>
        <SearchInput
          inputKey='square_from'
          label=''
          placeholder={t('common.table.from')}
          type='number'
          InputProps={{
            sx: {
              borderRadius: '8px 0 0 8px !important',
            },
            endAdornment: null,
            startAdornment: (
              <InputAdornment position='start' className='custom-endAdornment'>
                <Trans i18nKey='common.global.meter'>
                  m<sup>2</sup>
                </Trans>
              </InputAdornment>
            ),
          }}
        />
        <SearchInput
          inputKey='square_till'
          label=''
          placeholder={t('common.table.till')}
          type='number'
          InputProps={{
            sx: {
              borderRadius: '0 8px 8px 0 !important',
            },
            endAdornment: null,
          }}
        />
      </div>
      <div className='flex w-[300px]'>
        <SearchInput
          inputKey='stage_from'
          label=''
          placeholder={t('common.table.from')}
          type='number'
          InputProps={{
            sx: {
              borderRadius: '8px 0 0 8px !important',
            },
            endAdornment: null,
            startAdornment: (
              <InputAdornment position='start' className='custom-endAdornment'>
                {t('common.filter.stage')}
              </InputAdornment>
            ),
          }}
        />
        <SearchInput
          inputKey='stage_till'
          label=''
          placeholder={t('common.table.till')}
          type='number'
          InputProps={{
            sx: {
              borderRadius: '0 8px 8px 0 !important',
            },
            endAdornment: null,
          }}
        />
      </div>
    </div>
  );
});
