/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const ShowroomTopPanel = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [selectedObjectId, setSelectedObjectId] = useState('');
  const [selectedBlocksId, setSelectedBlocksId] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [objects, blocks] = useQueries([
    {
      queryKey: '/dictionary/objects2',
      queryFn: async function () {
        const response = await axiosPrivate.get('/dictionary/objects2');
        return response.data.data;
      },
      enabled: !hasError,
      onSuccess: (data) => {},
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: '/admin/block/index/{selectedObjectId}',
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/admin/block/index/${selectedObjectId}`
        );
        return response.data.data;
      },
      onSuccess: (data) => {
        const entries = Object.fromEntries(searchParams);
        const blocksFromPath = [];
        Object.keys(entries).forEach((item) => {
          if (item.includes('block')) {
            blocksFromPath.push(parseInt(entries[item]));
          }
        });
        setSelectedBlocksId(blocksFromPath);
      },
      enabled: false,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  const handleChangeObject = (event) => {
    const {
      target: { value },
    } = event;
    searchParams.set('objectId', value);
    clearAll();
    setSelectedBlocksId([]);
    setSelectedObjectId(value);
    setSearchParams(searchParams);
  };

  const handleChangeBlocks = (event) => {
    const {
      target: { value },
    } = event;
    searchParams.set(
      `block[${selectedBlocksId.length}]`,
      value[value.length - 1]
    );
    setSearchParams(searchParams);
    setSelectedBlocksId(value);
  };

  const handleDeleteBlockItem = (value) => {
    let leftSelectedBlocks = selectedBlocksId.filter((item) => item != value);
    clearAll();
    leftSelectedBlocks.forEach((item, index) =>
      searchParams.set(`block[${index}]`, item)
    );
    setSearchParams(searchParams);
    setSelectedBlocksId(leftSelectedBlocks);
  };

  const clearAll = () => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item.includes('block')) searchParams.delete(item);
    });
  };

  const getChipNameById = (id) => {
    let foundBlock =
      blocks.data &&
      blocks.data.length > 0 &&
      blocks.data.find((item) => item.id == id);
    if (foundBlock) {
      return foundBlock.name;
    }
    return '';
  };

  const handleClearSelectBox = () => {
    clearAll();
    setSelectedBlocksId([]);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (objects?.data?.length) {
      const { data } = objects;
      const entries = Object.fromEntries(searchParams);
      if (Object.keys(entries).includes('objectId')) {
        setSelectedObjectId(searchParams.get('objectId'));
      } else {
        setSelectedObjectId(data[0]?.id);
        searchParams.set('objectId', data[0]?.id);
        setSearchParams(searchParams);
      }
    }
  }, [objects?.data, searchParams]);

  useEffect(() => {
    if (selectedObjectId) {
      blocks.refetch();
    }
  }, [selectedObjectId]);

  return (
    <div className='flex flex-row items-center w-full'>
      <Grid container spacing={1} columns={12} width={600}>
        <Grid item={true} sm={6} xs={12}>
          <FormControl fullWidth color='formColor'>
            <Select
              labelId='objectId-label'
              id='objectId-select'
              onChange={handleChangeObject}
              value={selectedObjectId}
              color='formColor'
              variant='outlined'
              role='presentation'
              sx={{
                height: '58px',
                '.MuiSelect-select .desc': {
                  display: 'block',
                },
              }}
              MenuProps={{ id: 'objectId-select-menu', className: '!block' }}
              size='medium'
            >
              {objects.isLoading || objects.isFetching ? (
                <div className='circular-progress-box'>
                  <CircularProgress size={25} />
                </div>
              ) : objects.data && objects.data.length > 0 ? (
                objects.data.map((item, index) => (
                  <MenuItem
                    value={item.id}
                    key={index + 1}
                    className='small-size'
                  >
                    <div>{item.name}</div>
                    <div className='text-gray-400 hidden desc'>
                      {item?.address}
                    </div>
                  </MenuItem>
                ))
              ) : (
                <div>
                  <span className='no-data-found-wrapper select-box'>
                    <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </div>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} sm={6} xs={12}>
          <FormControl fullWidth color='formColor'>
            <div className='absolute top-1 left-3 pointer-events-none text-sm'>
              {t('common.filter.blocks')}
            </div>
            {!selectedBlocksId?.length && (
              <div className='absolute top-7 left-3 text-sm text-gray-400'>
                {t('boss.crm.calls.all')}
              </div>
            )}
            <Select
              labelId='blocks-filter-label'
              id='blocks-filter-multiple-chip'
              multiple
              value={selectedBlocksId}
              onChange={handleChangeBlocks}
              color='formColor'
              variant='outlined'
              sx={{
                height: '58px',
                border: 'none',
                '&>.MuiSelect-select': {
                  paddingTop: '22px',
                },
              }}
              MenuProps={{
                id: 'blocks-filter-select-menu',
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
                disableScrollLock: true,
              }}
              input={
                <OutlinedInput
                  id='blocks-filter-select-multiple-chip'
                  className='!outline-none !border-none'
                  variant='filterField'
                />
              }
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: 0.5,
                    overflow: 'hidden',
                  }}
                >
                  {selected?.map((value) => (
                    <Chip
                      key={`block-${value}`}
                      label={getChipNameById(value)}
                      variant='tableBadge'
                      color='primary'
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      size='small'
                      onDelete={() => handleDeleteBlockItem(value)}
                    />
                  ))}
                </Box>
              )}
              endAdornment={
                <InputAdornment position='end' className='custom-endAdornment'>
                  <IconButton
                    onClick={() => handleClearSelectBox()}
                    size='small'
                    variant='onlyIcon'
                    sx={{
                      display: selectedBlocksId.length > 0 ? '' : 'none',
                    }}
                    className='select-box-end-adornment-button'
                  >
                    <i className='bi bi-x end-adornment-close-button' />
                  </IconButton>
                </InputAdornment>
              }
            >
              {blocks.isLoading || blocks.isFetching ? (
                <div className='circular-progress-box'>
                  <CircularProgress size={25} />
                </div>
              ) : blocks.data && blocks.data.length > 0 ? (
                blocks.data.map((item, index) => (
                  <MenuItem
                    value={item.id}
                    key={`block-${index + 1}`}
                    className='small-size'
                  >
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <div>
                  <span className='no-data-found-wrapper select-box'>
                    <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                    {t('common.global.noDataFound')}
                  </span>
                </div>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
export default memo(ShowroomTopPanel);
