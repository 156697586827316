import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useTopPanel from 'hooks/useTopPanel';
import {
  ShowroomPanel,
  ShowroomTopFilter,
  ShowroomTopPanel,
} from './components';

export default memo(function Showroom() {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('showroom.title')}</div>);
  }, [i18n.language]);

  return (
    <div className='h-full'>
      <div className='base-border px-4 py-1 bg-white'>
        <ShowroomTopPanel />
      </div>
      <div className='base-border px-2 py-1 bg-white my-3.5'>
        <ShowroomTopFilter />
      </div>
      <div className='h-[calc(100%-180px)]'>
        <ShowroomPanel />
      </div>
    </div>
  );
});
