import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { motion } from 'framer-motion';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { fadeUp } from 'utils/motion';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';

const FromMultiSelect = ({
  delay = 0,
  duration = 0.5,
  formik,
  fieldName,
  path,
  label,
  readOnly = false,
  disabled = false,
  pathChangeable = false,
  withNestedField = false,
  isitemsizesmall = false,
  variant = 'outlined',
  emitRefetch = {},
}) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: fieldName + 'Select',
    queryFn: async function () {
      const response = await axiosPrivate.get(path);
      return response.data.data;
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    if (pathChangeable) {
      refetch();
      formik.setFieldValue(fieldName, '', true);
    }
  }, [path]);

  useEffect(() => {
    if (emitRefetch && emitRefetch.refetch) {
      refetch();
      emitRefetch.setRefetch(false);
    }
  }, [emitRefetch.refetch]);

  const getFieldValue = () => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b?.[a?.[i]];
      }
      return b;
    }
    return formik.values[fieldName] || '';
  };

  const getFieldValidation = (formikF) => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik[formikF];
      for (let i = 0; i < a.length; i++) {
        if (b?.[a?.[i]]) {
          b = b?.[a?.[i]];
        } else {
          b = false;
        }
      }
      return b;
    }
    return formik?.[formikF]?.[fieldName] || '';
  };
  const getChipNameById = (id) => {
    const foundBlock = data.filter((item) => item.id == id)[0];
    return `${foundBlock.name}`;
  };

  const handleDeleteItem = (value) => {
    const selectedValues = withNestedField
      ? getFieldValue()
      : formik.values[fieldName];
    const leftSelectedBlocks = selectedValues.filter((item) => item != value);
    formik.setFieldValue(fieldName, leftSelectedBlocks, true);
  };

  const clearAll = () => {
    formik.setFieldValue(fieldName, [], true);
  };

  const handleClearSelectBox = () => {
    clearAll();
  };

  return (
    <FormControl
      fullWidth
      color='formColor'
      component={motion.div}
      variants={fadeUp(30, 'tween', delay, duration)}
      initial='hidden'
      animate='show'
      viewport={{ once: true, amount: 0.25 }}
      error={
        withNestedField
          ? getFieldValidation('touched') &&
            Boolean(getFieldValidation('errors'))
          : formik.touched[fieldName] && Boolean(formik.errors[fieldName])
      }
    >
      <InputLabel id='blocks-filter-label'>{label}</InputLabel>
      <Select
        labelId='blocks-filter-label'
        id='blocks-filter-multiple-chip'
        multiple
        readOnly={readOnly}
        disabled={disabled}
        variant={variant}
        value={
          isLoading || isFetching
            ? []
            : withNestedField
            ? getFieldValue()
            : formik.values[fieldName]
        }
        onChange={(event) => {
          formik.setFieldValue(fieldName, event.target.value, true);
        }}
        input={
          <OutlinedInput
            id='blocks-filter-select-multiple-chip'
            variant='filterField'
            label={t('common.filter.blocks')}
          />
        }
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: 0.5,
              overflow: 'hidden',
            }}
          >
            {selected.map((value) => (
              <Chip
                key={`block-${value}`}
                label={getChipNameById(value)}
                variant='tableBadge'
                color='primary'
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onDelete={() => handleDeleteItem(value)}
              />
            ))}
          </Box>
        )}
        color='formColor'
        MenuProps={{
          id: 'blocks-filter-select-menu',
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          disableScrollLock: true,
        }}
        endAdornment={
          <InputAdornment position='end' className='custom-endAdornment'>
            <IconButton
              onClick={() => handleClearSelectBox()}
              size='small'
              variant='onlyIcon'
              sx={{
                display: withNestedField
                  ? getFieldValue()?.length
                  : formik.values[fieldName]?.length > 0
                  ? ''
                  : 'none',
              }}
              className='select-box-end-adornment-button'
            >
              <i className='bi bi-x end-adornment-close-button' />
            </IconButton>
          </InputAdornment>
        }
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : data && data.length > 0 ? (
          data.map((item, index) => (
            <MenuItem
              value={item.id}
              key={`block-${index + 1}`}
              className={isitemsizesmall ? 'small-size' : ''}
            >
              {item.name}
            </MenuItem>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Select>
      <FormHelperText
        children={
          <span>
            {withNestedField
              ? getFieldValidation('touched') &&
                formLocalizedHelperText(getFieldValidation('errors'))
              : formik.touched[fieldName] &&
                formLocalizedHelperText(formik.errors[fieldName])}
          </span>
        }
        error={
          withNestedField
            ? getFieldValidation('touched') &&
              Boolean(getFieldValidation('errors'))
            : formik.touched[fieldName] && Boolean(formik.errors[fieldName])
        }
      />
    </FormControl>
  );
};

export default memo(FromMultiSelect);
