import i18n from 'config/i18n';

function getLabelByTypeList(typeList = {}, code = '') {
  if (typeList && code?.toString()) {
    const result = Object.keys(typeList).find(
      (item) => typeList[item].code?.toString() == code?.toString()
    );
    return i18n.t(typeList[result].label);
  }
  return '';
}

export default getLabelByTypeList;
