import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import FormSelectField from 'components/ui/form/FormSelectField';
import FromMultiSelect from 'components/ui/form/FormMultiSelectField';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';

const validationSchema = yup.object({
  name: yup.string().required('settings.targets.validation.name'),
  key: yup.string().required('settings.targets.validation.keyword'),
  pixel_id: yup.string().required('settings.targets.validation.pixelId'),
  columns: yup.array().min(1, 'settings.targets.validation.keyword'),
  crm_block_item_id: yup
    .number()
    .required('settings.targets.validation.blockId'),
  source_id: yup.number().required('settings.targets.validation.sourceId'),
});

const TargetAddEditModal = (props) => {
  const { open, setOpen, setRefetch, itemId, setItemId } = props;
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      name: '',
      key: '',
      crm_block_item_id: '',
      source_id: '',
      pixel_id: '',
      crm_block_id: '',
      columns: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const columns = values.columns.map((id) => ({ id }));
      const data = { ...values, columns };

      submit(
        { type: itemId ? 'postUpdate' : 'post', contentType: 'simple' },
        data,
        '/crm/target',
        values.name,
        itemId,
        false,
        handleFinishRequest
      );
    },
  });

  const { isLoading, isFetching } = useQuery({
    queryKey: 'staffSingle',
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/target/edit/${itemId}`);
      return response.data.data;
    },
    onSuccess: (data) => {
      const columns = data?.columns?.map(
        ({ id, column_id }) => id || column_id
      );
      formik.setValues({
        name: data?.name || '',
        key: data?.key || '',
        crm_block_item_id: data?.crm_block_item_id || '',
        source_id: data?.source_id || '',
        pixel_id: data?.pixel_id || '',
        crm_block_id: data?.blockitem?.crm_block_id || '',
        columns: columns || [],
      });
    },
    enabled: !hasError && !!itemId,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleFinishRequest = () => {
    setRefetch(true);
    handleClose();
  };

  const handleClose = () => {
    setItemId('');
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {itemId && formik.values.name ? (
          <span>
            {t('settings.targets.modal.editTitle', {
              value: formik.values.name,
            })}
          </span>
        ) : (
          <span>{t('settings.targets.modal.addTitle')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {isLoading || isFetching ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} lg={4} sm={6} xs={12}>
                <FormSelectField
                  delay={0}
                  fieldName='crm_block_id'
                  label={t('common.filter.block')}
                  formik={formik}
                  path={'/crm/block'}
                  isitemsizesmall={true}
                />
              </Grid>
              {formik.values.crm_block_id && (
                <Grid item={true} lg={4} sm={6} xs={12}>
                  <FormSelectField
                    delay={0}
                    fieldName='crm_block_item_id'
                    label={t('common.table.funnel')}
                    formik={formik}
                    path={`/crm/block-item/${formik.values.crm_block_id}`}
                    isitemsizesmall={true}
                    pathChangeable={true}
                  />
                </Grid>
              )}
              <Grid item={true} lg={4} sm={6} xs={12}>
                <FromMultiSelect
                  delay={0}
                  fieldName='columns'
                  label={t('common.fields.squares')}
                  formik={formik}
                  path={'/target-columns'}
                  isitemsizesmall={true}
                />
              </Grid>
              <Grid item={true} lg={4} sm={6} xs={12}>
                <FormTextField
                  delay={0.1}
                  label={t('common.table.name')}
                  fieldName='name'
                  formik={formik}
                />
              </Grid>
              <Grid item={true} lg={4} sm={6} xs={12}>
                <FormTextField
                  delay={0.1}
                  label={t('common.fields.pixelId')}
                  fieldName='pixel_id'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} lg={4} sm={6} xs={12}>
                <FormTextField
                  delay={0.2}
                  label={t('common.table.keyword')}
                  fieldName='key'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} lg={4} sm={6} xs={12}>
                <FormSelectField
                  delay={0.2}
                  fieldName='source_id'
                  label={t('common.fields.source')}
                  formik={formik}
                  path={'/crm/source'}
                  isitemsizesmall={true}
                />
              </Grid>

              <Grid item={true} sm={12} xs={12}>
                <FormActionButtons
                  delay={0.6}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  reset={formik.resetForm}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(TargetAddEditModal);
