import moment from 'moment';
import MessageActionsMenu from '../actions-menu/MessageActionsMenu';
import { memo, useCallback } from 'react';
import CRMTaskForm from './CRMTaskForm';

const CRMFeatureTaskMessage = ({
  data = {},
  refetchFn = () => {},
  openId,
  setOpenId,
}) => {
  const onClick = useCallback(() => {
    if (openId != data?.id) setOpenId(data?.id);
    else setOpenId('');
  }, [data?.id, openId]);
  return (
    <div className='bg-white rounded-md p-2 px-3 mb-2'>
      <div
        className={'task-wrapper flex items-center relative cursor-pointer'}
        id={`task-${data?.id}`}
        onClick={onClick}
      >
        <div
          className={`task-icon text-base leading-4 border-2 ${
            data?.status == 1 ? 'border-blue-300' : 'border-gray-400'
          } text-gray-400 rounded-full w-[25px] h-[25px] min-w-[25px] min-h-[25px] flex items-center justify-center mr-3`}
        >
          <i
            className={`bi ${
              data?.status == 1
                ? 'bi-clock text-blue-300'
                : 'bi-journal-bookmark'
            } text-sm leading-none`}
          />
        </div>
        <div className='task-details w-full'>
          <div className='text-[13px] text-gray-700 font-semibold flex flex-col'>
            {moment(data?.date).format('DD.MM.YYYY HH:mm')}, {data?.name}
            &nbsp;{data?.subt?.name || ''}
            &nbsp;{data?.subt?.name && data?.title ? ' - ' : ''}
            &nbsp;{data?.title}
          </div>
        </div>
        <MessageActionsMenu itemId={data?.id} refetchFn={refetchFn} />
      </div>
      {data?.status == 1 && data?.id == openId && (
        <CRMTaskForm refetch={refetchFn} data={data} />
      )}
    </div>
  );
};
export default memo(CRMFeatureTaskMessage);
