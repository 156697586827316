/* eslint-disable react-hooks/exhaustive-deps */
import { Button, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import React, { Fragment, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';
import { fadeUp } from 'utils/motion';

export default function ChangeOnlyOne({
  delay = 0,
  duration = 0.5,
  fieldName,
  formik,
  label,
  prefix = '',
  readOnly = false,
  disabled = false,
  decimalScale = 3,
  variant = 'outlined',
  size = 'medium',
  initialFieldValueName,
  ...props
}) {
  const getFieldValue = () => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b?.[a?.[i]];
      }
      return b;
    }
    return formik.values?.[fieldName] || '';
  };

  const initialValue = useMemo(() => {
    if (initialFieldValueName && initialFieldValueName.includes('.')) {
      let a = initialFieldValueName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b?.[a?.[i]];
      }
      return b;
    }
    return formik.values?.[fieldName] || '';
  }, [initialFieldValueName, formik.values]);

  const getFieldValidation = (formikF) => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik[formikF];
      for (let i = 0; i < a.length; i++) {
        if (b?.[a?.[i]]) {
          b = b?.[a?.[i]];
        } else {
          b = false;
        }
      }
      return b;
    }
    return formik?.[formikF]?.[fieldName] || '';
  };

  const onAdd = () => {
    if (Number(initialValue || 0) >= Number(getFieldValue() || 0)) {
      formik.setFieldValue(fieldName, Number(getFieldValue() || 0) + 1, false);
    } else {
      formik.setFieldValue(fieldName, Number(getFieldValue() || 0) - 1, false);
    }
  };

  return (
    <Fragment>
      {formik && formik.values && formik.touched && formik.errors && (
        <div className='flex items-center gap-2'>
          <Button
            variant='outlined'
            sx={{
              minWidth: '30px',
              width: '30px',
              borderColor: '#818181',
              color: '#818181',
            }}
            onClick={onAdd}
            size='small'
          >
            {Number(initialValue || 0) >= Number(getFieldValue() || 0)
              ? '+'
              : '-'}
          </Button>
          <NumericFormat
            id={fieldName}
            name={fieldName}
            label={label}
            value={getFieldValue() || '0'}
            delay={delay}
            size={size}
            onChange={(event) => {
              let formattedValue =
                event.target.value &&
                parseFloat(event.target.value.split(' ').join(''));
              formik.setFieldValue(fieldName, formattedValue, true);
            }}
            error={
              getFieldValidation('touched') &&
              Boolean(getFieldValidation('errors'))
            }
            helperText={
              getFieldValidation('touched') &&
              formLocalizedHelperText(getFieldValidation('errors'))
            }
            component={motion.div}
            variants={fadeUp(30, 'tween', delay, duration)}
            initial='hidden'
            animate='show'
            viewport={{ once: true, amount: 0.25 }}
            color='formColor'
            variant={variant}
            fullWidth
            customInput={TextField}
            InputProps={{ readOnly: readOnly, disabled: disabled }}
            allowNegative={false}
            thousandSeparator={' '}
            decimalScale={decimalScale}
            prefix={prefix}
            {...props}
          />
        </div>
      )}
    </Fragment>
  );
}
