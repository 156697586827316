import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormTextField from 'components/ui/form/FormTextField';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  type: yup.string().required(),
});

export default memo(function ModalNewFieldMaker({
  open,
  setOpen,
  refetch,
  selected,
  setSelected,
}) {
  const handleClose = () => {
    setOpen(false);
    setSelected({});
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState([
    { name: '', _id: new Date().toString() },
  ]);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
    },
    validateOnBlur: validationSchema,
    // onSubmit: async (values) => {
    //   handleSave(values);
    // },
  });
  const { setValues } = formik;

  useEffect(() => {
    if (selected?.type && selected?.id) {
      setValues({ name: selected?.name, type: selected?.type });
      if (selected?.type === 'SELECT' && selected?.options?.length) {
        setOptions(selected?.options);
      }
    } else {
      setValues({ name: '', type: '' });
      setOptions([{ name: '', _id: new Date().toString() }]);
    }

    return () => {
      // cleanup
    };
  }, [selected, setValues]);

  const handleSave = async () => {
    setIsSubmitting(true);

    const noEmpty = options
      ?.filter(({ name }) => name)
      ?.map(({ name, id }) => ({ name, id }));

    const body =
      formik?.values?.type !== 'SELECT'
        ? formik?.values
        : { ...formik?.values, options: noEmpty };
    try {
      const response = await axiosPrivate.post(
        selected?.id
          ? `/crm/crm-settings/update/${selected?.id}`
          : '/crm/crm-settings/store',
        JSON.stringify(body),
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response && response.data && response.data.status) {
        refetch();
        setOpen(false);
        sendNotification({
          msg: response?.data?.message || response?.message,
          variant: 'success',
        });
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
    setIsSubmitting(false);
  };
  const onRemoveOption = (i) => {
    setOptions(options.filter((_, index) => index !== i));
  };
  const onOptionChange = (e, i) => {
    const { value } = e.target;
    setOptions((opts) =>
      opts?.map((opt, j) => (i === j ? { ...opt, name: value } : opt))
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none' }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title' className='!py-3 !bg-gray-100 !mb-4'>
        <span className='pr-5 text-base'>
          {t('crm.block.settings.fieldSettings.propertyOfFieldsTitle')}
        </span>
      </DialogTitle>
      <DialogContent>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <div className='min-w-96 w-full'>
          <FormSelectField
            delay={0}
            duration={0}
            fieldName='type'
            label={t('common.fields.type')}
            formik={formik}
            optionValueName='type'
            path={'/crm/crm-settings/types'}
            isitemsizesmall={true}
          />
        </div>
        <div>
          <FormTextField
            delay={0}
            duration={0}
            fieldName='name'
            label={t('common.fields.simpleName')}
            formik={formik}
            isitemsizesmall={true}
          />
        </div>

        {formik.values.type === 'SELECT' && (
          <div className='mt-3'>
            {options.map((option, index) => (
              <div key={option?._id || option?.id}>
                <div className='text-sm'>
                  {index + 1}-{t('common.fields.option')}
                </div>
                <div className='flex gap-2'>
                  <TextField
                    delay={0}
                    duration={0}
                    value={option?.name}
                    onChange={(e) => onOptionChange(e, index)}
                    fullWidth
                    isitemsizesmall={true}
                  />
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={() => onRemoveOption(index)}
                    sx={{
                      height: 40,
                      marginTop: 1,
                      minWidth: 40,
                      borderColor: 'red',
                    }}
                  >
                    <i className='bi bi-trash-fill text-red-600' />
                  </Button>
                </div>
              </div>
            ))}
            <div className='item-value mb-2'>
              <Button
                startIcon={<i className='bi bi-plus' />}
                size='small'
                variant='outlined'
                className='!text-gray-400 hover:!text-black'
                sx={{ borderStyle: 'dashed' }}
                type='submit'
                onClick={() =>
                  setOptions([
                    ...options,
                    { name: '', _id: new Date().toString() },
                  ])
                }
              >
                {t('common.button.addNew')}
              </Button>
            </div>
          </div>
        )}
        <div className='mt-3 text-right'>
          <Button
            variant='contained'
            sx={{ borderRadius: '25px' }}
            disabled={isSubmitting}
            onClick={handleSave}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} color='info' />
              ) : undefined
            }
          >
            {t('common.button.save')}
          </Button>
        </div>
        {/* </form> */}
      </DialogContent>
    </Dialog>
  );
});
