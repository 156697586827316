import React, { memo } from 'react';
import Floor from './Floor';
import { motion } from 'framer-motion';

export default memo(function Entrance({ entrance, entranceIndex, blockName }) {
  const getFullFloorsNumberByPadezd = (padezd) => {
    let a = Array.from(new Set(padezd.homes.map((item) => item.stage)));
    if (a.includes('-1')) {
      a.push('0');
    }
    a.sort((x, y) => x - y);
    return a.reverse();
  };

  return (
    <motion.div
      animate={{ x: 0, opacity: 1 }}
      initial={{ x: 50, opacity: 0.02 }}
      transition={{ delay: entranceIndex * 0.2 }}
      className='entrance rounded-3xl bg-gray-100 h-auto py-1.5 px-3'
    >
      <div className='text-base mb-[3px]'>{blockName}</div>
      <span className='font-medium text-sm text-gray-600 text-line-1 mb-2.5'>
        {entrance?.name}
      </span>
      {getFullFloorsNumberByPadezd(entrance).map((floorNumber, index) => (
        <div
          className={`sheet-row floor-${floorNumber}`}
          key={`block-${entrance?.id}-row-${index}`}
          id={`block-${entrance?.id}-row-${index}`}
        >
          <Floor
            homes={entrance?.homes}
            index={entranceIndex}
            floorNumber={floorNumber}
            size={new Set(entrance?.homes.map((item) => item.stage)).size}
          />
        </div>
      ))}
    </motion.div>
  );
});
