import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import TopPanelProvider from './providers/TopPanelProvider';
import NotificationProvider from './providers/NotificationProvider';
import CurrencyProvider from './providers/CurrencyProvider';
import useAuth from 'hooks/useAuth';
import MenuProvider from './providers/MenuProvider';
import { ShowroomProvider } from './ShowroomContext';

const BaseProvider = () => {
  const [{ user }] = useAuth();
  return (
    <Fragment>
      {user && user.user && (
        <TopPanelProvider>
          <NotificationProvider>
            <CurrencyProvider>
              <MenuProvider>
                <ShowroomProvider>
                  <Outlet />
                </ShowroomProvider>
              </MenuProvider>
            </CurrencyProvider>
          </NotificationProvider>
        </TopPanelProvider>
      )}
    </Fragment>
  );
};

export default BaseProvider;
